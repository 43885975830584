import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Container, Grid, Button } from '@material-ui/core'
import { LazyLoadImage } from 'react-lazy-load-image-component'

export default function TwoCol(props) {
    return (
        <Container className="my-20 md:my-64">
            <Grid container className={`flex items-center ${props.left === true ? 'md:flex-row-reverse' : '' }`}>
                <Grid item md={6} sm={12} className={`my-20 md:mb-0 ${props.left === true ? 'md:pl-40' : 'md:pr-40' }`}>
                    <div className="menu-container">
                        <h6 className="red-text">{props.subtitle}</h6>
                        <h3 className="mb-5">{props.title}</h3>
                    </div>
                    <div><ReactMarkdown children={props.description} /></div>
                    {props.button ? 
                        <Button className="mt-10 lowercase primary-button red-button"><a href={`${props.link}`}>{props.button}</a></Button>
                        :
                        null
                    }
                </Grid>
                <Grid item md={6} sm={12} className="md:text-center">
                <LazyLoadImage
                    alt="Mcreilly's pub image"
                    effect="blur"
                    src={props.img}
                />
                </Grid>
            </Grid>
        </Container>
    )
}