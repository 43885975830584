import React from "react"
import { Link, graphql } from "gatsby"
import { Container, Grid, Button } from "@material-ui/core"
import BackgroundImage from "gatsby-background-image"

import Layout from "../components/theme/layout"
import SEO from "../components/theme/seo"
import Social from "../components/theme/social"
import TwoCol from "../components/theme/two-col"
import Background from "../components/theme/backgroundImage"
import Logo from "../images/mcreilly-logo.svg"
import ToGo from "../images/slidertogo.png"
import BeerBG from "../images/bg-color.jpg"

export default function Home({ data }) {
  return (
    <Layout>
      {data.allStrapiMenu.edges.map(({ node }) => (
        <>
          <SEO title="McReilly's Pub" />
          <Background key={node.header.id}>
            <Container className="text-center white-text py-20 intro-fade">
              <img
                src={Logo}
                alt="McReilly's Logo"
                width="350px"
                className="mx-auto mb-10"
              />
              <h1>{node.header.title_description}</h1>
              <h5>{node.header.subheading}</h5>
              <Link
                to="/menu"
                className="inline-flex mx-auto my-10 border border-white"
              >
                <Button className="lowercase primary-button">
                  {node.header.button}
                </Button>
              </Link>
              <Social />
            </Container>
          </Background>
          <TwoCol
            key={node.Our_History.id}
            subtitle={node.Our_History.subheading}
            title={node.Our_History.title}
            description={node.Our_History.description}
            img={node.Our_History.image.publicURL}
          />
          <div className="beers">
            <div
              className="beers-bg"
              style={{ backgroundImage: `url(${BeerBG})` }}
            />
            <Container className="pb-6 my-20 ">
              <div
                className="beers-intro text-center"
                key={node.beer_section.id}
              >
                <h6 className="red-text">{node.beer_section.title}</h6>
                <h3 className="mb-5">{node.beer_section.subtitle}</h3>
              </div>
              <div className="beer-types">
                <Grid container className="flex justify-center">
                  {node.beer_col.map(beer => (
                    <Grid
                      item
                      md={4}
                      className="beer text-center mb-20"
                      key={beer.id}
                    >
                      <img src={beer.image.publicURL} className="mx-auto" />
                      <div className="w-9/12 mx-auto">
                        <h6 className="red-text mt-12">{beer.title}</h6>
                        <p>{beer.description}</p>
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </div>
            </Container>
          </div>
          <div className="mb-64" key={node.takeaway_info.id}>
            <TwoCol
              left={true}
              subtitle={node.takeaway_info.subtitle}
              title={node.takeaway_info.title}
              description={node.takeaway_info.description}
              img={ToGo}
              button={node.takeaway_info.button}
              link="tel:7057219521"
            />
          </div>
        </>
      ))}
    </Layout>
  )
}

export const query = graphql`
  query Home {
    allStrapiMenu {
      edges {
        node {
          header {
            id
            title_description
            subheading
            button
          }
          Our_History {
            id
            title
            subheading
            description
            image {
              publicURL
            }
          }
          beer_section {
            id
            title
            subtitle
          }
          beer_col {
            id
            title
            description
            image {
              publicURL
            }
          }
          takeaway_info {
            id
            title
            subheading
            description
            button
          }
        }
      }
    }
  }
`
